import { template as template_33fdc2284ea34bcba08c194802243059 } from "@ember/template-compiler";
const SidebarSectionMessage = template_33fdc2284ea34bcba08c194802243059(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
