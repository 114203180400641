import { template as template_2c57874e4fb44a5d876e1055a30ae7b3 } from "@ember/template-compiler";
const FKLabel = template_2c57874e4fb44a5d876e1055a30ae7b3(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
