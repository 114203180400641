import { template as template_ffa20e0a850a4f8b9947d2648d51a50c } from "@ember/template-compiler";
import { hash } from "@ember/helper";
import FKField from "discourse/form-kit/components/fk/field";
const FKInputGroup = template_ffa20e0a850a4f8b9947d2648d51a50c(`
  <div class="form-kit__input-group">
    {{yield
      (hash
        Field=(component
          FKField
          errors=@errors
          addError=@addError
          data=@data
          set=@set
          remove=@remove
          registerField=@registerField
          unregisterField=@unregisterField
          triggerRevalidationFor=@triggerRevalidationFor
          showMeta=false
        )
      )
    }}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKInputGroup;
